.form-control.form-control-solid.TranslationEditModalForm__locale-input {
  all: unset;
  width: fit-content;
  margin-right: 20px;
  text-align: center;
}

.TranslationEditModalForm__input-container {
  display: flex;
  align-items: center;
}

.TranslationEditModalForm__translation-textarea {
  height: auto;
}
