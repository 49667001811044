.FlagsDirectionBar {
  padding: calc(0.775rem + 1px) calc(1.5rem + 1px);
  display: flex;
  list-style: none;
}
.FlagsDirectionBar__flags > span {
  margin-right: 0 !important;
  &:not(:last-child) {
    margin-right: 5px;
  }
}

.FlagsDirectionBar__flags:not(:last-child) {
  margin-right: 15px;
}

.FlagsDirectionBar__flag {
  &:first-child {
    margin-right: 7px;
  }
}
