.StatusBar__status {
  padding: 2px 4px;
  font-weight: 600;
  border-radius: 5px;
  box-shadow: $btn-box-shadow;
}

.StatusBar_active {
  color: $active !important;
  background-color: $activeBg !important;
}

.StatusBar_blocked {
  color: $blocked !important;
  background-color: $blockedBg !important;
}

.StatusBar_on-hold {
  color: $on-hold !important;
  background-color: $on-holdBg !important;
}

.menu.menu-sub.menu-sub-dropdown.StatusBarSettings {
  position: relative;
  right: 180px;
  top: 200px;
}
.StatusBarSettings__delete-modal {
  padding: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.StatusBarSettings__controllers {
  justify-content: space-around;
}
.StatusBarSettings__controllers > * {
  width: 70px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1) !important;
}
.StatusBarSettings__confirm {
  margin-bottom: 40px;
}
