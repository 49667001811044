@import './custom/acceptTrackForm';

@import './custom/createDeliveryType';
@import './custom/createDirection';
@import './custom/createWebsite';

@import './custom/dashboard';
@import './custom/deleteConfirmation';
@import './custom/deliveryTypesItem';
@import './custom/deliveryTypeOne';
@import './custom/directions';

@import './custom/flagsDirectionBar';
@import './custom/formStyles';

@import './custom/itemStep';
@import './custom/itemSettings';

@import './custom/logoThumb';

@import './custom/mobileSettings';
@import './custom/modalNotification';

@import './custom/profilePage';

@import './custom/receivers';

@import './custom/settings';
@import './custom/statusBar';

@import './custom/trackPage';
@import './custom/tracksFilter';
@import './custom/translations';
@import './custom/translationEditModalForm';

@import './custom/userDirectionsCell';

//

.stepper-item.CustomStepper {
  display: flex;
  flex-direction: row;
}

*,
*::before,
*::after {
  // outline: 1px solid tomato;
}

.row-reverse {
  flex-direction: row-reverse;
}

.form-check-input {
  flex-shrink: 0;
}
.inactiveCheckbox {
  color: grey;
}

.activeCheckbox {
  color: inherit;
}
.flex-gutter > * {
  &:not(:last-child) {
    margin-right: 10px;
  }
}

.ws-preline {
  white-space: pre-line;
}

@include media-breakpoint-up(md) {
  .flex-md-gutter > * {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.flex-col-gutter > * {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.flex-col-rev-gutter > * {
  &:not(:first-child) {
    margin-bottom: 10px;
  }
}

.PaginationDropdown > button {
  background-color: grey !important;
  padding: 5px 10px !important;
  font-size: 0.8rem;
}

.bg-active {
  background-color: $activeBg;
}

.bg-blocked {
  background-color: $blockedBg;
}

.line-height-10px {
  line-height: 10px !important;
}

.balance-accordion-item.accordion-item {
  border: none;
  background-color: transparent;
}

.balance-accordion-header {
  & > .accordion-button {
    padding: 0;
  }
  & > .accordion-button:not(.collapsed) {
    background-color: transparent;
  }
}

.show-chat.custom-chat-button {
  font-family: var(--bs-btn-font-family) !important;
  background-color: transparent !important;
  color: black !important;
  font-size: var(--bs-btn-font-size) !important;
  &::before {
    all: unset !important;
  }
  &::after {
    all: unset !important;
  }
  &:hover {
    font-size: var(--bs-btn-font-size) !important;
  }

  & > .unread_count {
    all: unset !important;
    display: none !important;
  }
}

.TermsOfServiceModal_text-container ul {
  padding-left: 50px;
  & > li:not(:last-child) {
    margin-bottom: 10px;
  }
}

.react-photo-gallery--gallery img {
  object-fit: contain;
  width: 150px;
  height: 150px;
}

@media (max-width: 767.99px) {
  // AuthLayout
  .bg-images-none {
    background-image: none !important;
  }

  .minify-button {
    padding: calc(#{$btn-padding-y-sm} + #{$btn-border-width})
      calc(#{$btn-padding-x-sm} + #{$btn-border-width}) !important;
  }

  // stepper
  .stepper-nav:not(.active) .stepper-line {
    display: none !important;
  }
  .stepper-nav.active .stepper-line {
    display: block;
  }
  .stepper-item.CustomStepper:not(.current) {
    display: none !important;
  }
  .stepper-nav.active > .stepper-item.CustomStepper {
    display: block !important;
    transition: all 0.2s ease;
  }

  .stepper-item.CustomStepper.current .arrow {
    width: 1.25rem;
    height: 1.25rem;
    display: inline-block;
    position: relative;
    margin: 0 1rem;

    span {
      top: 0.5rem;
      position: absolute;
      width: 0.75rem;
      height: 0.1rem;
      background-color: #000000;
      display: inline-block;
      transition: all 0.2s ease;

      &:first-of-type {
        left: 0;
        transform: rotate(45deg);
      }

      &:last-of-type {
        right: 0;
        transform: rotate(-45deg);
      }
    }

    &.active {
      span {
        &:first-of-type {
          transform: rotate(-45deg);
        }
        &:last-of-type {
          transform: rotate(45deg);
        }
      }
    }
  }

  .nav-stretch li.nav-item {
    padding-bottom: 10px !important;
  }
}

.BasicTooltip {
  all: unset;
}

.payment-field {
  height: 46px !important;
}
.payment-field iframe {
  height: 100% !important;
}
.payment-field:has(.CollectJSValid) {
  border-color: $success !important;
}
.payment-field:has(.CollectJSInvalid) {
  border-color: $danger !important;
}

.custom-recent-invoices-accordion-header {
  .accordion-button {
    font-size: 14px !important;
    font-weight: 600 !important;
    padding: 7px 12px 7px 1px !important;

    &:not(.collapsed) {
      color: var(--kt-primary);
    }
    &::after {
      display: inline-block !important;
      color: hsl(0, 0%, 80%) !important;
      fill: hsl(0, 0%, 80%) !important;
      line-height: 1 !important;
      stroke: hsl(0, 0%, 80%) !important;
      stroke-width: 0 !important;
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='hsl(0, 0%, 80%)'><path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'/></svg>") !important;
    }
  }
}

.custom-recent-invoices-accordion-header.error * {
  background-color: rgb(202, 0, 0) !important;
}
#button {
  height: 40px !important;
}

@media (min-width: 1400px) {
  .app-container {
    max-width: 1600px;
    justify-content: center;
  }
  .app-header {
    justify-content: right;
  }
}

input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.scrolltop {
  bottom: 62px !important;
}

.TermsOfServiceModal_text-container {
  * {
    text-align: left !important;
  }
  p {
    font-size: 18px;
  }
  li,
  li > p {
    font-size: 16px;
  }
}

.truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
}

// bootstrap breakpoints

// // def
//  @media (max-width: 575px) {
// }
// // sm
// @media (min-width: 576px) {
// }

// // md
// @media (min-width: 768px) {
// }
// // lg
// @media (min-width: 992px) {
// }

// // xl
// @media (min-width: 1200px) {
// }

// // xxl
// @media (min-width: 1400px) {
// }
